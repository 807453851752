<template>
  <main role="main" class="main">
    <div class="bg-navy d-block d-md-none">
      <b-row class="no-gutters">
        <b-col class="text-center">
          <b-button type="button" variant="link" class="color-white float-left py-3" to="/transaction-history">
            <feather type="arrow-left"></feather>
          </b-button>
          <span class="d-inline-block h-100 title-w-60">
            <span class="d-table h-100">
              <span class="d-table-cell align-middle" v-if="link && link._id && link.transaction && link.transaction._id">
                <strong class="color-white">{{ link.transaction.name }}</strong>
              </span>
            </span>
          </span>
        </b-col>
      </b-row>
    </div>
    <div class="mobile-vh min-h-100 p-4 p-md-5 mobi-rounded-top">
      <b-row class="custom-height">
        <b-col lg="10" offset-lg="1" v-if="link && link._id && link.transaction && link.transaction._id">
          <h2 class="mobile-size-reduce color-navy-blue mb-0">Fund {{ link.transaction && link.transaction._id ? 'transaction' : 'wallet'}}</h2>
          <p class="text-uppercase mb-2 mb-md-4">Reference: {{ link.transaction.reference }}</p>
          <div class="h-md-60">
            <b-row>
              <b-col md="10" offset-md="1" class="d-block d-md-none position-static main list-group py-2 px-4 mt-0 mb-3" v-if="link && link._id">
                <b-row class="rounded box-shadow pt-4 pb-2 px-2">
                  <b-col v-if="link && link.transaction && link.transaction._id">
                    <h5 class="mb-3">
                      {{ link.transaction.name }}
                    </h5>
                    <div v-if="!link.transaction.payment || !link.transaction.payment.changeqty">
                      <p>
                        Transaction total<br />
                        <strong class="font-size-24 mobile-size-reduce">{{ formatCurrency(link.transaction.amounts.amount, link.transaction.amounts.currency, 2) }}</strong>
                      </p>
                      <p v-if="fees">
                        My portion of the fees *<br />
                        <strong class="font-size-20 mobile-size-reduce-sm">{{ formatCurrency(fees[0], link.transaction.amounts.currency, 2) }} - {{ formatCurrency(fees[1], link.transaction.amounts.currency, 2) }}</strong>
                      </p>
                      <p v-if="commission">
                        My portion of the commission *<br />
                        <strong class="font-size-20 mobile-size-reduce-sm">{{ formatCurrency(commission, link.transaction.amounts.currency, 2) }}</strong>
                      </p>
                      <p v-if="total">
                        Total payable *<br />
                        <strong class="font-size-20 mobile-size-reduce-sm">{{ formatCurrency(total[0], link.transaction.amounts.currency, 2) }} - {{ formatCurrency(total[1], link.transaction.amounts.currency, 2) }}</strong>
                      </p>
                      <p>
                        Date created<br />
                        <strong class="font-size-20 mobile-size-reduce-sm">{{ link.created_at | moment("D MMMM YYYY, LT") }}</strong>
                      </p>
                      <p v-if="loggedIn && link.created_by">
                        Created by{{ link.transaction && link.transaction.actors && link.transaction.actors.seller && link.created_by._id === link.transaction.actors.seller._id ? ' / Seller' : null }}<br />
                        <strong class="font-size-20 mobile-size-reduce-sm">{{ link.created_by | formatUserShort }}</strong>
                      </p>
                    </div>
                    <div v-else-if="inPayment">
                      <p>
                        <small class="float-right mt-1"><a href="#" class="text-uppercase" @click.prevent="$bvModal.show('change_qty')">Change quantities <feather type="arrow-right" size="0.75rem"></feather></a></small>
                        Items
                        <b-row v-for="(record, $index) in link.transaction.general.items" :key="$index">
                          <b-col cols="5">
                            {{ record.quantity }} x <strong>{{ record.name }}</strong>
                          </b-col>
                          <b-col cols="3" class="text-right">
                            {{ formatCurrency(record.price, link.transaction.amounts.currency, 2) }}
                          </b-col>
                          <b-col cols="4" class="text-right">
                            <strong>{{ formatCurrency(record.quantity * record.price, link.transaction.amounts.currency, 2) }}</strong>
                          </b-col>
                        </b-row>
                        <small v-if="_transaction && !_transaction.transaction.modified">Note: You haven't changed any of the item quantities yet.</small>
                      </p>
                      <p>
                        Transaction total<br />
                        <strong class="font-size-24 mobile-size-reduce">{{ formatCurrency(link.transaction.amounts.amount, link.transaction.amounts.currency, 2) }}</strong>
                      </p>
                      <p v-if="fees">
                        My portion of the fees *<br />
                        <strong class="font-size-20 mobile-size-reduce-sm">{{ formatCurrency(fees[0], link.transaction.amounts.currency, 2) }} - {{ formatCurrency(fees[1], link.transaction.amounts.currency, 2) }}</strong>
                      </p>
                    </div>
                    <div v-else>
                      <p class="line-height-1 mb-0" v-if="total">
                        <b>Total payable</b><br />
                        <small>Includes my portion of the Truzo fees and agent commission, if transaction includes an agent.</small>
                      </p>
                      <p v-if="total">
                        <strong class="font-size-24 mobile-size-reduce">{{ formatCurrency(total[0], link.transaction.amounts.currency, 2) }} - {{ formatCurrency(total[1], link.transaction.amounts.currency, 2) }}</strong>
                      </p>
                      <p>
                        <b>Date created</b><br />
                        <strong class="font-size-24 mobile-size-reduce-sm">{{ link.created_at | moment("D MMMM YYYY, LT") }}</strong>
                      </p>
                      <p v-if="link.created_by">
                        <b>Created by{{ link.transaction && link.transaction.seller && link.transaction.actors.seller && link.created_by._id === link.transaction.actors.seller._id ? ' / Seller' : null }}</b><br />
                        <strong class="font-size-24 mobile-size-reduce-sm">{{ link.created_by | formatUserShort }}</strong>
                      </p>
                    </div>
                    <p v-if="link.transaction && link.transaction.actors && link.transaction.actors.seller && link.transaction.actors.seller._id !== link.created_by._id">
                      <b>Seller</b><br />
                      <strong class="font-size-24 mobile-size-reduce-sm">{{ link.transaction.actors.seller | formatUser }}</strong>
                    </p>
                    <p v-if="link.transaction && link.transaction.actors && link.transaction.actors.seller_company">
                      <b>Seller company</b><br />
                      <strong class="font-size-24 mobile-size-reduce-sm">{{ link.transaction.actors.seller_company | formatCompany }}</strong>
                    </p>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <h5 class="mb-0">Your preferred payment method</h5>
            <p>Saved details that can be used for this payment. You can <a href="#" @click.prevent="clearPreference()">clear these details</a> at any time.</p>
            <ul class="list-unstyled row mx-n1 mx-md-n3 transaction-select" v-if="link && link.transaction && link.transaction._id">
              <li class="col-12 px-1 px-md-3 mb-3 mb-md-0">
                <a class="disabled mb-1 mb-md-3 box" tabindex="-1" v-if="!preferred">
                  <b-row>
                    <b-col cols="4" md="2">
                      <div class="icon mb-0">
                        <font-awesome-icon icon="battery-empty" size="3x" class="mt-3"></font-awesome-icon>
                      </div>
                    </b-col>
                    <b-col cols="8" md="3" class="text-left">
                      <p class="mt-1 mb-2"><strong>None saved</strong></p>
                      <strong></strong><br />
                      <p class="line-height-1 mb-0"><small><br /><br /></small></p>
                    </b-col>
                    <b-col cols="12" md="7" class="d-none d-md-block text-right my-auto">
                      <p class="my-auto">To save securely any of the payment methods to be used later, choose a method below, then select "Save as preferred payment method" before completing the payment.</p>
                    </b-col>
                    <b-col cols="12" md="7" class="d-block d-md-none text-center mt-3">
                      <p class="mb-0">You can save securely any of the payment methods, including specific payment details, as your preferred method to use later.</p>
                    </b-col>
                  </b-row>
                </a>
                <a class="disabled mb-1 mb-md-3 box" tabindex="-1" v-else-if="(getName(preferred.fee.method) === 'Card' && (amount + (preferred.fee.fee * buyersPercentage * 1.15 / 100)) >= 100000) || (getName(preferred.fee.method) === 'Truzo wallet' && (walletAvailable && (amount + (preferred.fee.fee * buyersPercentage * 1.15 / 100)) >= walletAvailable || !walletAvailable))">
                  <b-row>
                    <b-col cols="4" md="2">
                      <div class="icon mb-0">
                        <font-awesome-icon :icon="getIcon(preferred.fee.method)" size="3x" class="mt-3"></font-awesome-icon>
                      </div>
                    </b-col>
                    <b-col cols="8" md="3" class="text-left">
                      <p class="mt-1 mb-2"><strong>{{ getName(preferred.fee.method) }}</strong></p>
                      <strong>Unavailable</strong><br />
                      <p class="line-height-1 mb-0"><small><br /><br /></small></p>
                    </b-col>
                    <b-col cols="12" md="7" class="d-none d-md-block text-right my-auto" v-html="preferredDescription"></b-col>
                    <b-col cols="12" md="7" class="d-block d-md-none text-center mt-3" v-html="preferredDescription"></b-col>
                  </b-row>
                </a>
                <a href="#" class="mb-1 mb-md-3 box" @click.prevent="methodIndex = -1" :class="methodIndex === -1 ? 'active' : ''" v-else>
                  <b-row>
                    <b-col cols="4" md="2">
                      <div class="icon mb-0">
                        <font-awesome-icon :icon="getIcon(preferred.fee.method)" size="3x" class="mt-3"></font-awesome-icon>
                      </div>
                    </b-col>
                    <b-col cols="8" md="3" class="text-left">
                      <p class="mt-1 mb-2"><strong>{{ getName(preferred.fee.method) }}</strong></p>
                      <strong>{{ amount + preferred.fee.fee * buyersPercentage * 1.15 / 100 | formatAmount(link.transaction.amounts.currency) }}</strong><br />
                      <p class="line-height-1 mb-0"><small>Fee: {{ parseFloat(preferred.fee.fee * buyersPercentage / 100) | formatAmount(link.transaction.amounts.currency) }}<br />Vat: {{ preferred.fee.fee * buyersPercentage / 100 * 0.15 | formatAmount(link.transaction.amounts.currency) }}</small></p>
                    </b-col>
                    <b-col cols="12" md="7" class="d-none d-md-block text-right my-auto" v-html="preferredDescription"></b-col>
                    <b-col cols="12" md="7" class="d-block d-md-none text-center mt-3" v-html="preferredDescription"></b-col>
                  </b-row>
                </a>
              </li>
            </ul>
            <h5 class="mt-3 mb-0">Available payment methods</h5>
            <ul class="list-unstyled row mx-n1 mx-md-n3 transaction-select" v-if="link && link.transaction && link.transaction._id">
              <li class="col-12 px-1 px-md-3 mb-3 mb-md-0" v-for="(record, $index) in link.transaction.amounts.fees" :key="$index">
                <a class="disabled mb-1 mb-md-3 box" tabindex="-1" v-if="(getName(record.method) === 'Card' && (amount + (record.fee * buyersPercentage * 1.15 / 100)) >= 100000) || (getName(record.method) === 'Truzo wallet' && (walletAvailable && (amount + (record.fee * buyersPercentage * 1.15 / 100)) >= walletAvailable || !walletAvailable))">
                  <b-row>
                    <b-col cols="4" md="2">
                      <div class="icon mb-0">
                        <font-awesome-icon :icon="getIcon(record.method)" size="3x" class="mt-3"></font-awesome-icon>
                      </div>
                    </b-col>
                    <b-col cols="8" md="3" class="text-left">
                      <p class="mt-1 mb-2"><strong>{{ getName(record.method) }}</strong></p>
                      <strong>Unavailable</strong><br />
                      <p class="line-height-1 mb-0"><small><br /><br /></small></p>
                    </b-col>
                    <b-col cols="12" md="7" class="d-none d-md-block text-right my-auto">
                      <p class="my-auto" v-html="getDescription(record.method)"></p>
                    </b-col>
                    <b-col cols="12" md="7" class="d-block d-md-none text-center mt-3">
                      <p class="mb-0" v-html="getDescription(record.method)"></p>
                    </b-col>
                  </b-row>
                </a>
                <a href="#" class="mb-1 mb-md-3 box" @click.prevent="methodIndex = $index" :class="methodIndex === $index ? 'active' : ''" v-else>
                  <b-row>
                    <b-col cols="4" md="2">
                      <div class="icon mb-0">
                        <font-awesome-icon :icon="getIcon(record.method)" size="3x" class="mt-3"></font-awesome-icon>
                      </div>
                    </b-col>
                    <b-col cols="8" md="3" class="text-left">
                      <p class="mt-1 mb-2"><strong>{{ getName(record.method) }}</strong></p>
                      <strong>{{ amount + record.fee * buyersPercentage * 1.15 / 100 | formatAmount(link.transaction.amounts.currency) }}</strong><br />
                      <p class="line-height-1 mb-0"><small>Fee: {{ parseFloat(record.fee * buyersPercentage / 100) | formatAmount(link.transaction.amounts.currency) }}<br />Vat: {{ record.fee * buyersPercentage / 100 * 0.15 | formatAmount(link.transaction.amounts.currency) }}</small></p>
                    </b-col>
                    <b-col cols="12" md="7" class="d-none d-md-block text-right my-auto">
                      <p class="my-auto" v-html="getDescription(record.method)"></p>
                    </b-col>
                    <b-col cols="12" md="7" class="d-block d-md-none text-center mt-3">
                      <p class="mb-0" v-html="getDescription(record.method)"></p>
                    </b-col>
                  </b-row>
                </a>
              </li>
            </ul>
          </div>
          <b-form>
            <b-form-group class="btn-bottom">
              <b-row class="mt-xl-4">
                <b-col offset-xl="6" xl="6">
                  <loading-button :replace="actionStatus">
                    <b-button variant="custom" block pill :disabled="selected === 'Unknown' || (!amount || amount <= 0)" @click.prevent="complete()">
                      Next
                    </b-button>
                  </loading-button>
                </b-col>
              </b-row>
            </b-form-group>
          </b-form>
        </b-col>
      </b-row>
    </div>
    <stitch-fund-modal ref="stitch_modal" :url="ozowLink" :id="transaction ? transaction._id : null" :reference="transaction ? transaction.reference : null" :hash="stitchHash" :status="paymentStatus === 'paid'" v-if="preferred && preferred.type === 'instanteft'"></stitch-fund-modal>
    <secure-modal ref="3dsecure_comp2" :card="preferred.details._id" :transaction="transaction" :secure="paymentStatus === '3dsecure' ? payments.data : { payload: null, url: null, md: null }" v-if="preferred && preferred.type === 'card'"></secure-modal>
    <!-- <secure-modal ref="3dsecure_fnb" :transaction="transaction" :secure="paymentStatus === '3dsecure' ? payments.data : { txnToken: null, url: null }" v-if="$route.params.method === 'card'"></secure-modal> -->
    <b-modal id="complete_payment" size="lg" centered hide-header hide-footer no-close-on-backdrop no-close-on-esc>
      <div>
        <button type="button" aria-label="Close" class="close" @click="$bvModal.hide('complete_payment')">×</button>
        <h4 class="font-weight-400 mt-2 mb-3">Complete payment</h4>
        <p>You're about to complete the payment for transaction {{ transaction ? transaction.reference : 'unknown' }}<span class="font-20-bigger">{{ transaction ? ', ' + transaction.name : null }}</span>.</p>
        <p>The following actions will occur once the payment is completed:</p>
        <ul>
          <li><b>{{ userName }}</b> will be confirmed as a buyer for this transaction, and the transaction will be approved on your behalf.</li>
          <li>Total of <b>{{ transaction ? formatCurrency(transaction.amounts.amount + correspondingFee, transaction.amounts.currency, 2) : 'The funds' }}</b> will be debited from your <b>{{ debitFrom }}</b>.</li>
          <li><b>{{ transaction ? formatCurrency(transaction.amounts.amount, transaction.amounts.currency, 2) : 'The funds' }}</b> will be allocated to this transaction.</li>
          <li v-if="correspondingFee > 0">Corresponding Truzo fees of <b>{{ correspondingFee | formatAmount(transaction.amounts.currency, 2) }}</b> will be debited immediately. Please, refer to our <a href="https://truzo.com/terms-and-conditions/" target="_blank">Terms & Conditions <font-awesome-icon icon="link"></font-awesome-icon></a> for more details on Truzo fee allocation.</li>
          <li>The seller will be notified via their preferred communication channel and advised to start the delivery{{ transaction ? ' of ' : null }}<b>{{ transaction ? transaction.name : null }}</b>.</li>
          <li v-if="saveDetails">This payment method and corresponding details will be saved as your preferred method, and you will be able to fund your transactions or <b>Truzo wallet</b> in the future via a <b>single click payment</b> option.</li>
          <li v-if="dontShow">This confirmation prompt <b>will not be shown</b> to you on your next payment.</li>
        </ul>
        <b-row>
          <b-col cols="12">
            <b-form-group class="mb-0">
              <maz-checkbox v-model="dontShow">Do not show this again.</maz-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="12" md="6" order-md="2">
            <loading-button :replace="actionStatus">
              <b-button variant="custom" pill block class="mt-3" @click.prevent="doPayment()" :disabled="!canPay">Complete payment</b-button>
            </loading-button>
          </b-col>
          <b-col cols="12" md="6">
            <b-button variant="outline-custom" pill block class="mt-3" @click.prevent="$bvModal.hide('complete_payment')">
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-modal id="payment_error" size="md" centered hide-header hide-footer no-close-on-backdrop no-close-on-esc>
      <div>
        <button type="button" aria-label="Close" class="close" @click="$bvModal.hide('payment_error')">×</button>
        <h4 class="font-weight-400 mt-2 mb-0 text-center">Payment error</h4>
        <b-row>
          <b-col class="text-center">
            <feather type="alert-circle" size="10rem" stroke="#dc3545" class="my-3"></feather>
            <p v-if="alert.message"><b>{{ alert.message }}</b></p>
            <p v-else><b>There was an error processing your payment.</b></p>
          </b-col>
        </b-row>
        <p>Please, review the error and select one of the following available actions:</p>
        <ul>
          <li><a href="#" @click.prevent="retry()">Retry the payment</a>.</li>
          <li><a href="#" @click.prevent="$bvModal.hide('payment_error')">Verify the payment details</a> and then try the payment again.</li>
          <li>Select a <a href="#" @click.prevent="other()">different payment method</a>.</li>
          <li>Contact Truzo support via <a href="https://truzo.com/support/" target="_blank">our ticketing system</a>.</li>
        </ul>
        <b-row class="mb-2">
          <b-col cols="12" md="6" offset-md="6">
            <b-button variant="outline-custom" pill block class="mt-3" @click.prevent="$bvModal.hide('payment_error')">
              Close
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <change-qty-complete ref="change-qty-complete" v-on:do-payment="doPayment" />
  </main>
</template>

<script>
import randomstring from 'randomstring'
import { paymentService, walletService } from '@/services'

import ChangeQtyComplete from '@/components/modals/ChangeQtyComplete'
import SecureModal from '@/components/modals/3DSecureModalExisting'
import StitchFundModal from '@/components/modals/StitchFundModal'

import { mapActions, mapState } from 'vuex'

export default {
  components: {
    ChangeQtyComplete,
    SecureModal,
    StitchFundModal
  },
  data () {
    return {
      link: null,
      methodIndex: -1,
      templates: [
        { value: 'Card', name: 'Card', icon: ['far', 'credit-card'], description: 'We accept all Credit, Debit, Cheque and Hybrid cards issued by MasterCard&reg; and Visa&trade;.' },
        { value: 'EFT', name: 'Manual EFT', icon: 'pen-alt', description: 'We have trust accounts with ' },
        { value: 'InstantEFT', name: 'Instant EFT', icon: 'stopwatch', description: 'We\'ve integrated with <a href="https://ozow.com" target="_blank">Ozow</a> and <a href="https://stitch.money" target="_blank">Stitch</a> to facilitate secure and efficient Instant EFT payments on our platform.' },
        { value: 'Wallet', name: 'Truzo wallet', icon: 'wallet', description: '<b>Truzo wallet</b> is an easy, secure and affordable way to transact online and offline.' },
        { value: 'PayPal', name: 'PayPal', icon: 'paypal' }
      ],
      ozowLink: null,
      stitchHash: null,
      interval: null,
      cardId: null,
      saveDetails: false,
      dontShow: false
    }
  },
  mounted () {
    const _enabled = localStorage.getItem('paymentconfirmation')
    if (_enabled === 'disabled') {
      this.dontShow = true
    }

    if (this.auth.user.company) {
      this.getCompany()
    }

    paymentService.getLink(this.linkid)
      .then(
        link => {
          if (link) {
            this.link = link
            walletService.getWalletBankDetails(this.currency)
              .then(
                banks => {
                  if (banks && banks.length > 0) {
                    const _banks = []
                    banks.forEach(bank => {
                      if (_banks.indexOf(bank.name) < 0) {
                        _banks.push(bank.name)
                      }
                    })
                    
                    const _template = this.templates.find(_template => _template.value === 'EFT')
                    if (_banks.length === 1) {
                      _template.description += _banks[0]
                    } else if (_banks.length > 1) {
                      for (let i = 1; i <= _banks.sort().length; i++) {
                        if (i === _banks.length) {
                          _template.description += _banks[i - 1] + '.'
                        } else if (i === _banks.length - 1) {
                          _template.description += _banks[i - 1] + ' and '
                        } else {
                          _template.description += _banks[i - 1] + ', '
                        }
                      }
                    } else {
                      _template.description += ' major global banks.'
                    }

                    _template.description += ' <b>Please note:</b> It may take up to 2 working days to allocate the funds.'
                  } else {
                    const _template = this.templates.find(_template => _template.value === 'EFT')
                    _template.description += ' major global banks.'
                    _template.description += ' <b>Please note:</b> It may take up to 2 working days to allocate the funds.'
                  }
                },
                error => {
                  const _template = this.templates.find(_template => _template.value === 'EFT')
                  _template.description += ' major global banks.'
                  _template.description += ' <b>Please note:</b> It may take up to 2 working days to allocate the funds.'
                  console.log(error)
                }
              )

            this.getWallet(this.currency)

            if (link.transaction && link.transaction._id) {
              this.getTransaction(link.transaction._id)
            }
          } else {
            this.link = {
              link: 'Payment link is not available. Please, try to generate a new one.'
            }
          }
        },
        error => {
          console.log(error)
        }
      )
  },
  computed: {
    ...mapState({
      alert: state => state.alert,
      auth: state => state.auth.user,
      banks: state => state.banks.StitchBanks,
      company: state => state.company,
      payment: state => state.link,
      payments: state => state.payments,
      record: state => state.transaction,
      status: state => state.auth,
      txn: state => state.transaction,
      transaction: state => state.transaction.transaction,
      txnActions: state => state.txnActions,
      user: state => state.user,
      wallet: state => state.wallet,
      _transaction: state => state.transactionChangeQty
    }),
    loggedIn () {
      return this.status.status.loggedIn
    },
    inPayment () {
      if (this.loggedIn && this.$route.path.indexOf('/pay') >= 0) {
        return true
      } else {
        return false
      }
    },
    loadStatus () {
      return this.record.status
    },
    paymentStatus () {
      return this.payments.status
    },
    txnActionStatus () {
      return this.txnActions.status
    },
    txnStatus () {
      return this.txn.status
    },
    actionStatus () {
      return this.paymentStatus === 'paying' || this.paymentStatus === 'loading' || this.paymentStatus === '3dsecure' || this.txnActionStatus === 'saving'
    },
    userName () {
      if (this.status.user && this.status.user.user) {
        if (this.status.user.user.company) {
          return this.status.user.user.company_name
        } else {
          return this.status.user.user.name
        }
      } else {
        return 'You'
      }
    },
    correspondingFee () {
      if (this.link && this.link._id && this.preferred) {
        if (this.transaction && this.transaction._id) {
          if (this.buyersPercentage && this.buyersPercentage > 0) {
            const _fee = this.transaction.amounts.fees.find(_fee => _fee.method.name.toLowerCase() === this.preferred.type)
            if (_fee) {
              return _fee.fee * this.buyersPercentage / 100 * 1.15
            } else {
              return 0
            }
          } else {
            return 0
          }
        } else {
          return 0
        }
      } else {
        return 0
      }
    },
    linkid () {
      if (this.payment && this.payment.type === 'payment' && this.payment.id) {
        return this.payment.id
      } else {
        return false
      }
    },
    fees () {
      if (this.link && this.link._id) {
        if (this.link.transaction && this.link.transaction._id) {
          const _amounts = this.link.transaction.amounts
          const _split = _amounts.fee_payable_by.buyer_percentage / 100
          const _minimum = Math.min.apply(Math, _amounts.fees.map(function(o) { return o.fee })) * _split * 1.15
          const _maximum = Math.max.apply(Math, _amounts.fees.map(function(o) { return o.fee })) * _split * 1.15
          return [_minimum, _maximum]
        } else {
          return false
        }
      } else {
        return false
      }
    },
    commission () {
      if (this.link && this.link._id) {
        if (this.link.transaction && this.link.transaction._id) {
          const _amounts = this.link.transaction.amounts
          const _split = _amounts.split.find(_record => _record.actor === 'Buyer')
          if (_split && _split.agent_comm && _split.agent_comm.length > 0) {
            return _split.agent_comm[0].fee
          } else {
            return 0
          }
        } else {
          return 0
        }
      } else {
        return 0
      }
    },
    total () {
      if (this.link && this.link._id) {
        if (this.link.transaction && this.link.transaction._id) {
          const _amounts = this.link.transaction.amounts
          const _total = this.commission + _amounts.amount
          return [_total + this.fees[0], _total + this.fees[1]]
        } else {
          return false
        }
      } else {
        return false
      }
    },
    amount () {
      if (this.link && this.link._id) {
        if (this.link.transaction && this.link.transaction._id) {
          const _amounts = this.link.transaction.amounts
          const _total = this.commission + _amounts.amount
          return _total
        } else {
          return false
        }
      } else {
        return false
      }
    },
    buyersPercentage () {
      if (this.link && this.link._id) {
        if (this.link.transaction && this.link.transaction._id) {
          const _amounts = this.link.transaction.amounts
          return _amounts.fee_payable_by.buyer_percentage
        } else {
          return 0
        }
      } else {
        return 0
      }
    },
    preferred () {
      if (this.loggedIn) {
        let _preferred = null
        let _cards = null

        if (this.status.user.user.company) {
          _preferred = this.company && this.company.company ? this.company.company.preferred : null
          _cards = this.company && this.company.company ? this.company.company.cards : null
        } else {
          _preferred = this.user && this.user.user ? this.user.user.preferred : null
          _cards = this.user && this.user.user ? this.user.user.cards : null
        }

        if (this.link && this.link.transaction) {
          const _fees = this.link.transaction && this.link.transaction._id ? this.link.transaction.amounts.fees : []
          if (_preferred && _fees && _fees.length > 0) {
            const _fee = _fees.find(_fee => _fee.method.name.toLowerCase() === _preferred.type)
            if (_fee) {
              if (_preferred.type === 'card' && _cards && _cards.length > 0) {
                const _card = _cards.find(_card => _card._id === _preferred.details.id)
                if (_card) {
                  return {
                    type: 'card',
                    details: _card,
                    fee: _fee
                  }
                } else {
                  return null
                }
              } else {
                return {
                  type: _preferred.type,
                  details: _preferred.details,
                  fee: _fee
                }
              }
            } else {
              return null
            }
          } else {
            return null
          }
        } else {
          return null
        }
      } else {
        return null
      }
    },
    preferredDescription () {
      if (this.preferred) {
        if (this.preferred.type === 'card') {
          const _card = this.preferred.details
          let _description = '<p class="mb-1"><b>' + _card.card_name + ', ' + _card.card_number + ', ' + _card.expiry_date + '</b></p>'
          _description += '<p class="mb-0"><b>Please note:</b> You may still be asked to verify your payment either by entering your CVC or via 3D Secure verification process.'
          return _description
        } else if (this.preferred.type === 'instanteft') {
          const _account = this.preferred.details.account
          if (_account) {
            const _bank = this.banks.find(_bank => _bank.name === _account.bankId)
            let _description = '<p class="mb-1"><b>' + (_bank ? _bank.truzo + '<br />' : null) + _account.name + ', ' + _account.accountNumber + '</b></p>'
            _description += '<p class="mb-0"><b>Please note:</b> You may still be asked to verify your payment via your bank\'s multifactor challenge.'
            return _description
          } else {
            const method = this.preferred.type
            return this.templates.find(template => template.value.toLowerCase() === method || template.value === method.name).description
          }
        } else {
          const method = this.preferred.type
          return this.templates.find(template => template.value.toLowerCase() === method || template.value === method.name).description
        }
      } else {
        return null
      }
    },
    selected () {
      if (this.methodIndex >= 0) {
        if (this.link && this.link.transaction && this.link.transaction._id) {
          return this.link.transaction.amounts.fees[this.methodIndex].method.name
        } else {
          return 'Unknown'
        }
      } else if (this.methodIndex === -1 && this.preferred) {
        if ((this.preferred.type === 'card' && (this.amount + (this.preferred.fee.fee * this.buyersPercentage * 1.15 / 100)) >= 100000) || (this.preferred.type === 'wallet' && (this.walletAvailable && (this.amount + (this.preferred.fee.fee * this.buyersPercentage * 1.15 / 100)) >= this.walletAvailable || !this.walletAvailable))) {
          return 'Unknown'
        } else {
          return 'Preferred'
        }
      } else {
        return 'Unknown'
      }
    },
    debitFrom () {
      let _method = null
      if (this.methodIndex >= 0) {
        if (this.link && this.link.transaction && this.link.transaction._id) {
          _method = this.link.transaction.amounts.fees[this.methodIndex].method.name
        }
      } else if (this.methodIndex === -1 && this.preferred) {
        _method = this.preferred.fee.method.name
      }

      if (_method) {
        if (_method === 'Card') {
          if (this.methodIndex === -1 && this.preferred && this.preferred.details) {
            return 'Card, number ' + this.preferred.details.card_number + ', expiring on ' + this.preferred.details.expiry_date
          } else {
            return 'Credit / Debit card'
          }
        } else if (_method === 'Wallet') {
          return 'Truzo wallet'
        } else if (_method === 'EFT') {
          return 'Bank account selected via your EFT transfer'
        } else if (_method === 'InstantEFT') {
          const _account = this.preferred && this.preferred.details ? this.preferred.details.account : null
          if (_account) {
            return _account.name + ', ' + _account.accountNumber
          } else {
            return 'Bank account selected via your Instant EFT transfer'
          }
        } else {
          return 'chosen payment method'
        }
      } else {
        return 'chosen payment method'
      }
    },
    currency () {
      if (this.link) {
        if (this.link.transaction && this.link.transaction._id) {
          return this.link.transaction.amounts.currency
        } else if (this.link.wallet_fund && this.link.wallet_fund._id) {
          return this.link.wallet_fund.currency
        } else {
          return 'ZAR'
        }
      } else {
        return 'ZAR'
      }
    },
    walletStatus () {
      return this.wallet[this.currency].status
    },
    walletAvailable () {
      if (this.link) {
        if (this.wallet[this.currency].status === 'loaded') {
          return this.wallet[this.currency].wallet.available
        } else {
          return null
        }
      } else {
        return null
      }
    },
    canPay () {
      return this.selected === 'Preferred'
    },
    iteration () {
      return this._transaction.iteration
    }
  },
  methods: {
    ...mapActions('company', {
      getCompany: 'get'
    }),
    ...mapActions('link', ['clear']),
    ...mapActions('payments', {
      payCard: 'payCard',
      payEft: 'payEft',
      payStitch: 'payStitch',
      payWallet: 'payWallet',
      prepareTxn: 'prepareTxn'
    }),
    ...mapActions('transaction', {
      getTransaction: 'get'
    }),
    ...mapActions('transactionChangeQty', {
      clearQties: 'clear',
      updateQties: 'update',
      updateQtyState: 'updateState'
    }),
    ...mapActions('transactionSelected', {
      setTransactions: 'setTransactions'
    }),
    ...mapActions('txnActions', ['accept', 'action']),
    ...mapActions('user', {
      clearPreference: 'clearPreference'
    }),
    ...mapActions('wallet', {
      getWallet: 'get'
    }),
    formatCurrency: function (value, currency, numDigits) {
      if (typeof value !== 'number') return value
      const _numDigits = numDigits === null || numDigits === undefined ? 2 : numDigits
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency || 'ZAR',
        minimumFractionDigits: _numDigits,
        maximumFractionDigits: _numDigits
      })

      const _rounded = Math.round(value * 1000) / 1000
      const _value = formatter.format(_rounded).replace('ZAR', 'R')
      return _value
    },
    getName: function (method) {
      return this.templates.find(template => template.value === method || template.value === method.name).name
    },
    getDescription: function (method) {
      return this.templates.find(template => template.value === method || template.value === method.name).description
    },
    getIcon: function (method) {
      return this.templates.find(template => template.value === method || template.value === method.name).icon
    },
    complete: function () {
      if (this.selected === 'Preferred') {
        if (this.dontShow) {
          this.doPayment()
        } else {
          this.$bvModal.show('complete_payment')
        }
      } else {
        this.$router.push('/pay/' + this.selected.toLowerCase())
      }
    },
    doPayment: function () {
      if (this.transaction && this.transaction.payment && this.transaction.payment.changeqty) {
        this.$bvModal.show('change-qty-complete')
        this.$refs['change-qty-complete'].verifyQty()
      } else {
        const _link = this.transaction.links.find(_link => _link.actor === 'buyer')
        if (_link) {
          this.actioning = true
          this.accept({ id: _link._id, transaction: this.transaction._id, company: this.auth.user.company })
        } else {
          if (this.transaction.approvals.buyer) {
            this.payNow()
          } else {
            this.actioning = true
            this.action({ action: 'approve', id: this.transaction._id })
          }
        }
      }
    },
    payNow: function () {
      if (this.preferred.type=== 'eft') {
        this.payEft(this.transaction._id)
      } else if (this.preferred.type === 'wallet') {
        this.payWallet({
          id: this.transaction._id,
          saveDetails: this.saveDetails
        })
      } else if (this.preferred.type === 'instanteft') {
        this.stitchHash = randomstring.generate({ length: 32, charset: 'hex', capitalization: 'lowercase' })
        this.payStitch({ id: this.transaction._id, hash: this.stitchHash, forcenew: false })
      } else if (this.preferred.type === 'card') {
        this.payCard({
          id: this.transaction._id,
          card: this.preferred.details._id,
          saveDetails: this.saveDetails
        })
      }
    },
    retry: function () {
      this.$bvModal.hide('payment_error')
      this.complete()
    },
    other: function () {
      this.$bvModal.hide('payment_error')
    }
  },
  watch: {
    iteration (value) {
      const _transaction = this._transaction.transaction
      if (_transaction && _transaction.id && _transaction.link === this.linkid) {
        if (this.link.transaction && this.link.transaction._id) {
          this.link.transaction.amounts = _transaction.amounts
          this.link.transaction.general = _transaction.general
        }

        if (value === 1) {
          this.$bvModal.show('change_qty')
        }
      }
    },
    linkid (value) {
      if (!value) {
        this.$router.push('/')
      }
    },
    txnActionStatus (_status) {
      if (_status === 'loaded' && this.actioning) {
        this.actioning = false
        this.payNow()
      }
    },
    txnStatus (_status) {
      if (_status === 'loaded') {
        if (this.transaction.payment && this.transaction.payment.changeqty) {
          if (this._transaction.transaction.modified) {
            const _transaction = this._transaction.transaction
            if (_transaction && _transaction.id && _transaction.link === this.linkid) {
              if (this.link.transaction && this.link.transaction._id) {
                this.link.transaction.amounts = _transaction.amounts
                this.link.transaction.general = _transaction.general
              }
            }
          } else {
            if (this._transaction.id) {
              if (this._transaction.link !== this.linkid) {
                this.clearQties()
              }
            }

            const _transaction = {
              id: this.transaction._id,
              link: this.linkid,
              type: 'goods',
              amounts: this.transaction.amounts,
              modified: false,
              general: this.transaction.general
            }
            _transaction.amounts.amount = 0
            _transaction.amounts.fee = 0
            _transaction.amounts.fees.forEach(_fee => {
              _fee.fee = 0
            })
            _transaction.general.items.forEach(_item => {
              _item.selected = false
              _item.available = _item.quantity
              _item.quantity = 0
            })

            this.updateQties({ transaction: _transaction })
          }
        }
      }
    },
    paymentStatus: async function (_status) {
      if (_status === 'loaded') {
        this.$bvModal.hide('complete_payment')
        this.ozowLink = this.payments.data
        this.$bvModal.show('stitchfund-modal')
        this.$refs.stitch_modal.submitForm()
      }

      if (_status === 'paid') {
        if (this.dontShow) {
          localStorage.setItem('paymentconfirmation', 'disabled')
        }

        await paymentService.updateLink(this.linkid, 'Completed')
        this.clear()

        this.$router.push('/success')
      }

      if (_status === 'failed') {
        this.$bvModal.hide('complete_payment')
        this.$bvModal.show('payment_error')
      }

      if (_status === '3dsecure') {
        this.$bvModal.hide('complete_payment')
        this.$bvModal.show('3dsecure_existing')
        this.$refs['3dsecure_comp2'].submitForm()
      }
    },
    walletStatus (value) {
      if (value === 'loaded' && this.walletAvailable) {
        const _template = this.templates.find(_template => _template.value === 'Wallet')
        _template.description += '<br />Your current <b>Truzo wallet</b> balance is <b>' + this.formatCurrency(this.walletAvailable, this.currency, 0) + '</b>.'
      }
    }
  }
}
</script>
