<template>
  <main role="main" class="main bg-navy">
    <div class="bg-navy d-block d-md-none">
      <b-row class="no-gutters">
        <b-col class="text-center">
          <b-button type="button" variant="link" class="color-white float-left py-3" to="/home">
            <feather type="arrow-left"></feather>
          </b-button>
          <span class="d-inline-block h-100 title-w-60">
            <span class="d-table h-100">
              <span class="d-table-cell align-middle">
                <strong class="color-white">Payments</strong>
              </span>
            </span>
          </span>
        </b-col>
      </b-row>
    </div>
    <div class="mobile-vh min-h-100 p-4 p-md-5 bg-white mobi-rounded-top">
      <b-row class="custom-height">
        <b-col lg="10" offset-lg="1">
          <div class="h-md-100">
            <b-form-group class="text-center v-center">
              <span class="indicator bg-success">
                <div class="d-table h-100 w-100">
                  <div class="d-table-cell align-middle">
                    <feather type="check"></feather>
                  </div>
                </div>
              </span>
              <h2 class="color-navy-blue">Payment succeeded!</h2>
              <p>Thank you for choosing Truzo.<br />Please, tell us about your experience during this process.</p>
            </b-form-group>
            <div class="pt-3 px-md-5 pb-md-3 pb-5 text-center" v-if="webhook">
              <hr width="80%" />
              <p>We've noticed that this transaction was created via <strong>{{ record.transaction.partner.name }}</strong> by <strong>{{ record.transaction.partner.partner }}</strong>.</p>
              <p>We will redirect you automatically in <strong>{{ countdown }}</strong> seconds.</p>
              <b-form class="text-left px-md-4">
                <b-row>
                  <b-col>
                    <b-button class="pl-0" @click="clearCountdown()" variant="link">Don't redirect me.</b-button>
                  </b-col>
                  <b-col>
                    <b-button class="pr-0 float-right" @click="takeMeNow()" variant="link">Take me there now!</b-button>
                  </b-col>
                </b-row>
              </b-form>
            </div>
          </div>
          <b-form-group class="btn-bottom">
            <b-row>
              <b-col md="6" order-md="2">
                <b-button type="submit" variant="custom" pill block class="mt-3" to="/home">Home</b-button>
              </b-col>
              <b-col md="6">
                <b-button variant="outline-custom" pill block class="mt-3" href="#" @click.prevent="logMeOff()">Logout</b-button>
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </main>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  data () {
    return {
      countdown: 15,
      interval: null
    }
  },
  computed: {
    ...mapState({
      record: state => state.transaction
    }),
    webhook () {
      if (this.record && this.record.transaction && this.record.transaction.partner) {
        const _partner = this.record.transaction.partner
        if (_partner.webhooks && _partner.webhooks.enabled) {
          if (_partner.webhooks.client && _partner.webhooks.client.success) {
            return _partner.webhooks.client.endpoint
          } else {
            return false
          }
        } else {
          return false
        }
      } else {
        return false
      }
    }
  },
  methods: {
    ...mapActions('auth', ['logout']),
    logMeOff: function () {
      this.logout()
      this.$router.push('/')
    },
    clearCountdown: function () {
      clearInterval(this.interval)
      this.interval = null
    },
    takeMeNow: function () {
      window.location = this.webhook
    }
  },
  mounted () {
    if (this.webhook) {
      const self = this
      this.interval = setInterval(() => {
        self.countdown--
      }, 1000)
    }
  },
  watch: {
    countdown (value) {
      if (value === 0) {
        this.clearCountdown()
        this.takeMeNow()
      }
    }
  }
}
</script>